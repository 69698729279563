import React from 'react';
import { useState, useEffect } from 'react';
// import axios from 'axios';

import SearchList from './SearchList';

function SearchableCourseExam() {
    const [searchText, setSearchText] = useState('');
    const [show_course_units, setShow_course_units] = useState(false);
    const [loading, setLoading] = useState(false);
    const [initialExams, setInitialExams] = useState([]);
   
    /*
    * load course exam papers from db, for exam schedule searchable component.
    */
    /*    
    useEffect(() => {
        var url = 'http://localhost:8000/api/business';
        axios.get(url)
        .then(function(response){
           // console.log(response.data.courses_papers)
           setInitialExams(response.data.courses_papers);
        })
        .catch(function(error){
            console.log(error);
        });
    }, []);
    */
    var data = require('./business_july-2023_exams.json');
    useEffect(() => {
        setInitialExams(data);
    }, [data]);
    // for(var i = 0; i < data.length; i++){
    //     var obj = data[i];
    //     console.log('subject_code: ' + obj.subject_code);
    // }
    // console.log(initialExams)
    
    const filteredExams = initialExams.filter(
        exam => {
          return (
            exam
            .subject_code
            .toLowerCase()
            // .startsWith(searchText.toLowerCase()) ||
            .includes(searchText.toLowerCase()) ||
            exam
            .subject_name
            .toLowerCase()
            // .startsWith(searchText.toLowerCase())
            .includes(searchText.toLowerCase())
          );
        }
    );

    const handleChange = e => {
        e.preventDefault();
        setLoading(true);
        setSearchText(e.target.value);
        setLoading(false);
        /*
        if(e.target.value === ""){
            setShow_course_units(false);
        } else {
            setShow_course_units(true);
        }*/
        setShow_course_units(true);
    };

    if(loading) {
		var course_units_TABLE =
                <div className="card-body">
    				<tr>
                        <td colSpan="6">
    					    <h4>searching . . .</h4>
    					</td>
                    </tr>
    			</div>
	} else {
        if (show_course_units === true){
            course_units_TABLE = 
                <SearchList course_units={filteredExams} search_text={searchText}/>
        } else {
            course_units_TABLE = '';
        }
    }

	return(
        <div className="container">
            <div className="card">
            {/*main component*/}

    		    <div className="card-header text-success">
                    {/*header*/}
                    {'Business Exams July 2023'}
                </div>

               {/*search component*/}
                {/* SearchableKnecCourses courses={courses}/>*/}
    		    <div className="card-body">

                    <label className="">
                        {'Enter Course Unit Code or Name...'}
                    </label>
                   
                    <input 
                        className="btn btn-outline-success btn-sm btn-block"
                        type="text"
                        placeholder = "eg ICT or 2701/101"
                        value={searchText}
                        onChange={handleChange}
                    />
    
                </div>

                {/* course units component.*/}
                {/* to appear only to show results.*/}
                { course_units_TABLE }
    	
    		    <div className="card-footer">
                    {/*footer*/}
                    {'gaas! © 2023'}
                </div>

            </div>
        </div>
	);
}

export default SearchableCourseExam;