import React from 'react';

import Scroll from './Scroll';
import ResultsTable from './ResultsTable';

function SearchList({course_units, search_text}) {

    /*
    // +sometimes after typing, its like everything hangs,
    // ie the character appears, sometimes not appeared,
    // the results comp. doesnt show anything.
    // +this condition was to avoid that.
    //
    if(course_units.length === ''){
		var results = 
			<div>
				{'... searcing for ' + searchText +' just a second...'}
			</div>
	} else */
    // if theres no results.
    if(course_units.length === 0){
		var results = 
			<div>
				<h6>
					{ search_text +' is not a unit code nor unit name! try another'}
				</h6>
			</div>
	} else {
		results = 
            <Scroll>
			    <ResultsTable course_units={course_units} />
            </Scroll>
	}

	return(
            <div className="card-body">
            {/*business exams list component*/}
                {/* updated on to be aligned left, italic */}
            	{'*updated 30th June 2023'} 

				{ results }

				{/* disclaimer to be aligned right, italic*/}           
                {' disclaimer: all times are on EAT'}
			</div>
	);
}

export default SearchList;