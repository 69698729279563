import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './components/App';
// import CourseExamTimeTable from './components/CourseExamTimeTable';

ReactDOM.render(
		<App />,
  document.getElementById('root')
);

/*ReactDOM.render(
		<CourseExamTimeTable />,
  document.getElementById('root')
);*/