import React from 'react';

import SearchableCourseExam from './SearchableCourseExam';

function App() {
  return (
    <div>
      <SearchableCourseExam />
    </div>
  );
}
export default App;